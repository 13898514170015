import React from 'react'
import Layout from '../components/layout'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Head from '../components/head'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faClock, faBook, faTags } from '@fortawesome/free-solid-svg-icons'

import blogStyles from '../styles/blog.module.scss'

const BlogPage = () => {
  const blogs = useStaticQuery(graphql`
    query {
        allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
          edges {
            node {
              frontmatter{
                title
                date(formatString: "DD MMMM, YYYY")
                category
                author
                authorImage
                draft
                tags
              }
              html
              excerpt
              timeToRead
              wordCount {
                words
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `)

  //Create tags with icon using the values provided in tags list
  function TagsComponent(props) {
    let arrayString = props.arrayString;
    let tagsArray = arrayString.split(',');
    let tags = tagsArray.join(', ');
    if (tags !== 'null') {
      return <span><FontAwesomeIcon icon={faTags} color="green" /><span className={blogStyles.badges}>{tags}</span></span>;
    } else {
      return null;
    }
  }
  return (
    <div>
      <Layout>
        <Head title="Blog" />
        <div className={blogStyles.headContainerStyle}>
          <div className={blogStyles.headerFloatLeft}>Blogs</div>
          <Link to='/tags'><div className={blogStyles.tagsFloatRight}>View all Tags<FontAwesomeIcon icon={faTags} className={blogStyles.allTagsIconStyle}/></div></Link>
        </div>
        <div>
          <ol className={blogStyles.posts}>
            {blogs.allMarkdownRemark.edges.map((edge) => {
              let path = `/blog/${edge.node.frontmatter.category}/${edge.node.fields.slug}`;
              let tagsString = `${edge.node.frontmatter.tags}`;
              let isNotADraft = `${edge.node.frontmatter.draft}` === "false";

              if (isNotADraft) {
                return (
                  <li className={blogStyles.post} key={edge.node.fields.slug}>
                    <Link to={path}>
                      <h2>{edge.node.frontmatter.title}</h2>
                      <p className={blogStyles.subHeader}>
                        <FontAwesomeIcon icon={faCalendarAlt} color="orange" /><span className={blogStyles.badges}>{edge.node.frontmatter.date}</span>
                        <FontAwesomeIcon icon={faClock} color="red" /><span className={blogStyles.badges}>{edge.node.timeToRead} min read</span>
                        <FontAwesomeIcon icon={faBook} color="brown" /><span className={blogStyles.badges}>{edge.node.wordCount.words} words</span>
                        <TagsComponent arrayString={tagsString} />
                      </p>
                      <p className={blogStyles.excerptStyle}>{edge.node.excerpt}</p>
                      <p>
                        <span className={blogStyles.roundAvatar}><img src={edge.node.frontmatter.authorImage} alt="avatar" /></span>
                        <span className={blogStyles.authorName}>{edge.node.frontmatter.author}</span>
                      </p>
                    </Link>
                  </li>
                )
              } else {
                return null;
              }
            })}
          </ol>
        </div>
      </Layout>
    </div>
  )
}

export default BlogPage