import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import headerStyles from '../styles/header.module.scss'

const Header = () => {
    const data = useStaticQuery(graphql`
    query {
        site {
          siteMetadata {
            title,author
          }
        }
      }
    `)

    return (
        <header className={headerStyles.header}>
            <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
            <h1>
                <Link className={headerStyles.title} to="/">
                    {data.site.siteMetadata.author}
                </Link>
            </h1>
            <nav>
                <ul className={headerStyles.navList}>
                    <li><Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/">Home</Link></li>
                    {/* <li><Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/about">About</Link></li> */}
                    <li><Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/contact">Contact</Link></li>
                    <li><Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/blog">Blog</Link></li>
                </ul>
            </nav>
        </header>
    )
}

export default Header