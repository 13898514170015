import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'

const ContactPage = () => {
    return (
        <Layout>
            <Head title="Resume" />
            <iframe src="/uploads/CV.pdf" width="100%" height="600" title="resume"></iframe>
        </Layout>

    )
}

export default ContactPage