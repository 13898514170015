import React from "react"
import PropTypes from "prop-types"
import Head from '../components/head'
import Layout from '../components/layout'
import blogStyles from '../styles/blog.module.scss'
import tagsStyles from '../styles/tags.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTags } from '@fortawesome/free-solid-svg-icons'

// Components
import { Link, graphql } from "gatsby"

const Tags = ({ pageContext, data }) => {
    const { tag } = pageContext
    const { edges, totalCount } = data.allMarkdownRemark
    const tagHeader = `${totalCount} post${
        totalCount === 1 ? "" : "s"
        } tagged with "${tag}"`

    return (
        <div>
            <Layout>
                <Head title={`${tag} Tag`} />
                <Link to='/tags'><div className={blogStyles.tagsFloatRight}>View all Tags<FontAwesomeIcon icon={faTags} className={blogStyles.allTagsIconStyle}/></div></Link>
                <h2>{tagHeader}</h2>
                <ul className={tagsStyles.postsContainer}>
                    {edges.map(({ node }) => {
                        const { slug } = node.fields
                        let title = node.frontmatter.title
                        let category = node.frontmatter.category
                        let blogLink = '/blog/' + category + '/' + slug;
                        return (
                            <li key={slug}>
                                <Link to={blogLink}>{title}</Link><br />
                            </li>
                        )
                    })}
                </ul>
            </Layout>
        </div>
    )
}

Tags.propTypes = {
    pageContext: PropTypes.shape({
        tag: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            totalCount: PropTypes.number.isRequired,
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    node: PropTypes.shape({
                        frontmatter: PropTypes.shape({
                            title: PropTypes.string.isRequired,
                        }),
                        fields: PropTypes.shape({
                            slug: PropTypes.string.isRequired,
                        }),
                    }),
                }).isRequired
            ),
        }),
    }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, draft: { ne: true } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            category
          }
        }
      }
    }
  }
`