import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../components/layout'
import Head from '../components/head'
import homePageStyles from '../styles/home.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faLinkedinIn, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faMapMarkerAlt, faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby'

const IndexPage = () => {
    return (
        <div>
            <Layout>
                <Head title="Home" />
                <div className={homePageStyles.roundAvatar}>
                    <img src='/uploads/abhishek_homeImage_2021.png' alt="My Pic" />
                    <div>
                        <p className={homePageStyles.leftInfoStyle}>
                            <FontAwesomeIcon icon={faBriefcase} color="Gray" />
                            <span className={homePageStyles.leftContentStyle}>
                                Salesforce Developer
                            </span>
                        </p>
                        <p className={homePageStyles.leftInfoStyle}>
                            <FontAwesomeIcon icon={faMapMarkerAlt} color="orange" />
                            <span className={homePageStyles.leftContentStyle}> Bengaluru, India</span>
                        </p>
                        <div className={homePageStyles.iconsTopMargin}>
                            <p className={homePageStyles.leftInfoStyle}>
                                <a href="https://www.linkedin.com/in/abskpr" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedinIn} color="#0e76a8" /></a>
                                <span className={homePageStyles.iconStyle}><a href="https://github.com/abskpr" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGithub} color="black" /></a></span>
                                <span className={homePageStyles.iconStyle}><a href="https://twitter.com/abskpr" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} color="#00acee" /></a></span>
                                <span className={homePageStyles.iconStyle}><a href="mailto:prakash.abhishek9@gmail.com"><FontAwesomeIcon icon={faEnvelope} color="red" /></a></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className={homePageStyles.detailStyle}>
                    <p>Hello, I'm Abhishek, 3X certified Salesforce Developer since 2017, with experience in Community, Sales and Service cloud. My current focus is Aura Components and Lightning Web Components.</p>
                    <p>In my free time I like to watch movies and catch up on latest trends in technology.</p>
                    <p>I also try to share my learnings in blog form, click <Link to="/blog" className={homePageStyles.linkInTextStyle}>here</Link> to see my blogs.</p>
                </div>
            </Layout>
        </div>
    )
}

export default IndexPage
