import React from "react"
import PropTypes from "prop-types"
import Layout from '../components/layout'
import Head from '../components/head'

// Utilities
import kebabCase from "lodash/kebabCase"

//Styles
import tagsStyles from '../styles/tags.module.scss'

// Components
import { Link, graphql } from "gatsby"

const TagsPage = ({
    data: { allMarkdownRemark: { group } } }) =>
    (
        <div>
            <Layout>
                <Head title={'Tags'} />
                <div>
                    <h2>{'Tags (' + group.length + ')'}</h2>
                    <hr />
                    <ul className={tagsStyles.tagsContainer}>
                        {group.map(tag => (
                            <li key={tag.fieldValue}>
                                <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                                    {tag.fieldValue} ({tag.totalCount})
                                    </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </Layout>
        </div>
    )

TagsPage.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            group: PropTypes.arrayOf(
                PropTypes.shape({
                    fieldValue: PropTypes.string.isRequired,
                    totalCount: PropTypes.number.isRequired,
                }).isRequired
            ),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                title: PropTypes.string.isRequired,
            }),
        }),
    }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
        filter: { frontmatter: { draft: { ne: true } } }
        limit: 2000
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`