import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'
import contactStyles from '../styles/contact.module.scss'

const ContactPage = () => {
    return (
        <Layout>
            <Head title="Contact" />
            <span className={contactStyles.formHeaderText}>Get in Touch</span>
            <form name="contact" className={contactStyles.formContainer} method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
                {/* You still need to add the hidden input with the form name to your JSX form */}
                <input type="hidden" name="form-name" value="contact" />
                <div className={contactStyles.nameInputStyle}><input type="text" name="name" placeholder="Your Name (required)" required/></div>
                <div className={contactStyles.emailInputStyle}><input type="email" name="email" placeholder="Your Email (required)" required/></div>
                <div className={contactStyles.messageInputStyle}><textarea name="message" placeholder="Message goes here..." rows="3"></textarea></div>
                <div className={contactStyles.sendButtonStyle}><button type="submit">Send</button></div>
            </form>
        </Layout>

    )
}

export default ContactPage