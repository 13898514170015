import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'

import footerStyles from '../styles/footer.module.scss'

const Footer = () => {
    const data = useStaticQuery(graphql`
    query {
        site {
          siteMetadata {
            title,author
          },
          buildTime(formatString: "YYYY")
        }
      }
    `)
    return (
        <footer className={footerStyles.footer}>
            <p className={footerStyles.centerText}>Created by {data.site.siteMetadata.author} © {data.site.buildTime} | All rights reserved</p>
        </footer>
    )
}

export default Footer