import React from 'react'
import { graphql, Link } from 'gatsby'
import { DiscussionEmbed } from "disqus-react"
import Head from '../components/head'

import Layout from '../components/layout'

import blogStyles from '../styles/blog.module.scss'

export const query = graphql`
query ($slug: String!) {
    markdownRemark ( fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY")
        category
        comments
        tags
        authorImage
        author
      }
      timeToRead
      wordCount {
        words
      }
      html
      fields {
        slug
      }
    }
    site {
      siteMetadata {
        siteURL
      }
    }
  }
`

//Create tags with link to tags page using the values provided in tags list
function TagsComponent(props) {
  let tagsArray = props.tagsArray;
  if (tagsArray) {
    let tagsJSX = [<span className={blogStyles.tagParaStyle}>Tagged with </span>];
    for (var i = 0; i < tagsArray.length; i++) {
      var tagsLink = '/tags/' + tagsArray[i];
      if (i !== tagsArray.length - 1) {
        tagsJSX.push(<span><Link to={tagsLink} className={blogStyles.tagLinkStyle}>{tagsArray[i]}</Link>, </span>);
      } else {
        tagsJSX.push(<span><Link to={tagsLink} className={blogStyles.tagLinkStyle}>{tagsArray[i]}</Link></span>);
      }
    }
    return tagsJSX;
  } else {
    return <div></div>
  }
}

const Blog = (props) => {
  // Disqus shortname below, required for identifying where comments are coming from
  const disqusShortName = "abskpr";
  const post = props.data.markdownRemark;
  const disqusConfig = {
    url: `${props.data.site.siteMetadata.siteURL + '/blog/' + post.frontmatter.category + '/' + post.fields.slug}`,
    identifier: post.fields.slug,
    title: post.frontmatter.title,
  };

  function CommentsComponent(props) {
    if (props.showComments) {
      return <span><DiscussionEmbed shortname={disqusShortName} config={disqusConfig} /></span>
    } else {
      return null;
    }
  }

  return (
    <Layout>
      <Head title={post.frontmatter.title} />
      <div className={blogStyles.blogTitleStyle}>
        <h1>{post.frontmatter.title}</h1>
        <p>{post.frontmatter.date} &bull; <span className={blogStyles.blogTimeStyle}>{post.timeToRead} min read</span> &bull; {post.wordCount.words} words</p>
      </div>
      <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
      <div className={blogStyles.tagsComponentStyle}>
        <TagsComponent tagsArray={post.frontmatter.tags} />
      </div>
      <hr></hr>
      <p className={blogStyles.blogCreditStyle}>
        <img src={post.frontmatter.authorImage} alt="avatar" className={blogStyles.roundAvatarCredit} />
        <span className={blogStyles.blogCreditAuthorName}>{post.frontmatter.author}</span> is a Salesforce Developer since 2017, living in Bengaluru, India. Know more about him <Link to='/' className={blogStyles.creditAuthorLink}>here</Link>.
      </p>
      <div className={blogStyles.commentStyle}>
        <CommentsComponent showComments={post.frontmatter.comments} />
      </div>
    </Layout>
  )
}

export default Blog